import {
    Concept,
    IBusinessUnit,
    IThresholdValue
} from "../../application/api/applicationModels";
import {DayPartType} from "../../metricDetails/api/metricDayPartDataModels";
import {
    BusinessUnitAggregation,
    DrilldownType
} from "features/metricDetails/api/metricDetailsModels";
import {DataType} from "util/dataFormatUtil";
import {
    ModuleType,
    PageCode
} from "features/configuration/api/uiConfigurationModels";
import {DateTime} from "luxon";

export interface IMetricRequest {
    clientConcept_PK: number;
    date: Date;
    calendarId: number;
    concept: Concept;
    metricCodes?: string[];
    abortSignal?: AbortSignal;
}

export interface ICardRequest {
    page_PK: number;
    clientConcept_PK: number;
    businessUnit: IBusinessUnit;
    date: Date;
    calendarId: number;
    concept: Concept;
    metricCodes?: string[];
    abortSignal?: AbortSignal;
}

export interface IMetricValue {
    [xtd: string]: number | null;
}

export interface IMetricData {
    [code: string]: IMetricValue;
}

export interface IBusinessUnitMetricData {
    businessUnitId: string;
    businessUnitPk: number;
    businessUnitName: string;
    businessUnitDescription: string;
    parentBusinessUnitName: string;
    isLocation: boolean;
    date: Date;
    metricData: IMetricData;
    metricDataThresholdViolation: IXtdMetricDataThresholdViolationData;
    rankPTD: number;
}

export interface IXtdMetricDataThresholdViolationData {
    [code: string]: {[xtd: string]: boolean};
}

export interface IConceptMetric {
    conceptId: number;
    metricDefinitionId: number;
    metricCode: string;
    defaultName: string;
    description: string;
}

export interface IMetricDefinition {
    id: number;
    metricCode: string;
    direction: string;
    description: string;
    isCumulative: string;
    thresholdDescription: string;
    metricType: string;
}

// Note: This basically maps ClientConceptPageMetricModule which would probably be a better name for it
export interface IModuleMetricDefinition {
    id?: number;
    //renderingId is used as a unqiue key for rendering. Important for the Configurator when it
    //adds new ModuleMetric configurations, but those don't yet have an id
    renderingId?: number;
    clientConcept_PK: number;
    page_PK: number;
    pageCode: PageCode;
    moduleType: ModuleType;
    metricDefinitionId?: number;
    metricCode: string;
    metricName: string;
    drilldownPage_PK?: number;
    drilldownPageCode?: PageCode;
    dataType: DataType;
    sortOrder: number;
    toggleOrder: number;
    metricOrder: number;
    decimalPlaces?: number;
    unit?: string;
    defaultSort?: boolean;
    metricDirection?: MetricDirection;
    metricOrderCategory?: string;
    moduleTypeSortOrder?: number;
    dayPartType?: DayPartType;
    drilldownType?: DrilldownType;
    hasFilter?: boolean;
    active?: boolean; // This is used for toggling functionality
    tooltip?: string;
}

export interface IMetricThreshold {
    metricCode: string;
    isCumulative: boolean;
    description: string;
    thresholdValues: {[xtd: string]: IThresholdValue};
}

export interface IBusinessUnitMetricDataResult {
    metricDefinitions: IModuleMetricDefinition[];
    metricData: IBusinessUnitMetricData[];
}

export interface IGroupKpiData {
    metricData: IBusinessUnitMetricData;
    metricThresholds: IMetricThreshold[];
}

export interface IMetricDataDailyThresholdViolationCounts {
    metricDataDailyThresholdViolationCounts: IMetricDataDailyThresholdViolationCounts[];
}

export interface IMetricDataDailyThresholdViolationCounts {
    metricCode: string;
    locationsWithinThreshold: number;
    locationsViolatingThreshold: number;
    notReporting: number;
}

export interface IDailyMetricDataMapping {
    // mapping from a locationPk to a daily data
    [locationPk: number]: IBusinessUnitMetricDataResult;
}

export enum Xtd {
    Unknown = "",
    DAILY = "DAILY",
    WTD = "WTD",
    PTD = "PTD",
    QTD = "QTD",
    YTD = "YTD",
    CUSTOM = "CUSTOM"
}

export enum ThresholdType {
    None = "None",
    HigherIsBetter = "HigherIsBetter",
    LowerIsBetter = "LowerIsBetter",
    WithinRange = "WithinRange"
}

// these need to match property names of the source data object
export enum StaticColumnKey {
    BusinessUnitKey = "businessUnitName",
    ParentBusinessUnitKey = "parentBusinessUnitName",
    RankPTDKey = "rankPTD",
    itemNumber = "itemNumber",
    itemName = "itemName",
    itemUOM = "itemUOM"
}

export enum MetricDirection {
    HigherIsBetter = "HigherIsBetter",
    LowerIsBetter = "LowerIsBetter",
    CloserToTarget = "CloserToTarget",
    None = "None"
}
export interface ISubscription {
    id: number | undefined;
    name: string;
    page_PK: number;
    businessUnitId: string;
    businessUnitName?: string;
    deliveryTimeMinutesFromMidnight: number;
    formattedDeliveryTime?: string;
    xtd: Xtd;
    emailAddress: string;
    businessUnitAggregation: BusinessUnitAggregation;
    timezone: DateTime;
    timezoneName: string | undefined;
}
